.async-select__menu .option__flex:hover .option__flex_right {
  text-indent: 5px;
}

.async-select__container {
  flex-grow: 2;
}

.async-select__option {
  word-break: break-word;
  height: auto !important;
  padding: 7px !important;
}

.base-select-dark__menu .select-filter__item > span {
  margin-left: 10px;
  margin-top: 2px;
  line-height: 1.1;
  text-align: left;
  word-break: break-all;
  white-space: normal;
}
.base-select-dark__menu .select-filter__item {
  padding: 4px;
  border-radius: 4px;
}
.base-select-dark__menu .select-filter__item:hover {
  background-color: var(--color-light-dark);
  color: #fff;
}
.base-select-dark__multi-value__label .onollo-checkbox {
  display: none;
}
.base-select-dark__multi-value__label .select-filter__item > span {
  margin-left: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 45px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.option__flex {
  display: flex;
  align-items: center;
}

.option__flex_left {
  flex-shrink: 0;
  font-size: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #efefef;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 7px 0 5px;
}

.option__flex_left img {
  width: 100%;
  height: 100%;
}

.option__flex_right {
  transition: all 0.1s ease-in-out;
  margin-left: 19px;
}

.option__flex_right span {
  display: block;
  margin-top: 2px;
  font: 500 13px 'AvenirNextCyr', sans-serif;
  color: #949da0;
}

.option__flex_left + .option__flex_right {
  margin-left: 0;
}

#root .fill-grey-select__control {
  border: none;
  min-height: auto;
  background: #f4f4f4;
  border-radius: 100px;
  padding: 5px 10px;
  flex-wrap: nowrap;
}

#root .fill-grey-select__value-container {
  flex-shrink: 0;
  padding: 0;
  flex: none;
  min-width: calc(100% - 18px);
  position: static;
  display: block;
}

#root .fill-grey-select__value-container input {
  position: absolute;
}

#root .fill-grey-select__single-value {
  margin: 0;
  max-width: none;
  font: 400 15px AvenirNext;
  color: #000;
  position: static;
  transform: translateY(0);
}

#root .fill-grey-select__indicator {
  padding: 0;
  background: none;
  position: static;
  margin-left: 10px;
}

#root .fill-grey-select__indicator:after {
  content: '';
  display: block;
  border-top: 3px solid #000;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

#root .fill-grey-select__loading-indicator {
  display: none !important;
}

#root .fill-grey-select__indicator svg {
  display: none;
}

#root .fill-grey-select__menu .onollo-select__option-with-checkbox-name {
  color: #000;
  margin-left: 0;
  width: 100%;
  font-size: 14px;
}

#root .fill-grey-select__option {
  font: 400 15px AvenirNext;
  color: #000;
  background-color: #f4f4f4;
  padding: 6px 10px;
  height: auto;
}

#root .fill-grey-select__option:active {
  background-color: #f4f4f4;
}

#root .fill-grey-select__option--is-selected {
  background-color: #f4f4f4;
}

@media (max-width: 480px) {
  .async-select__placeholder,
  .async-select__single-value,
  .async-select__menu {
    flex-wrap: nowrap;
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .async-select__value-container {
    flex-wrap: nowrap !important;
  }
}
