.account-tabs{
    margin-top: 25px;
}

.account-tabs__list{
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
}

.no-account{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.no-account__message{
    width: 100%;
    text-align: center;
    color: var(--color-dark);
}
