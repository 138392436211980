#___reactour .reactour__helper {
  max-width: 360px;
  padding: 20px;
  background-color: #000;
  border-radius: 17px;
  color: #fff;
  font: 400 16px AvenirNext, sans-serif;
}

.reactour__helper h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 6px;
  padding-right: 15px;
}

#___reactour > *:first-child {
  opacity: .3;
}

.reactour__helper > *:last-child:hover {
  color: #5e5e5e;
}

[data-tour-elem="badge"] {
  display: none;
}

#___reactour .reactour__dot {
  margin: 0;
  border: none;
  width: 9px;
  height: 9px;
  background-color: #fff;
}

#___reactour .reactour__dot:hover:before {
  opacity: 1;
}

#___reactour .reactour__dot:not(:last-of-type) {
  margin-right: 5px;
}

#___reactour .reactour__dot--is-active {
  background-color: #6EBB2A;
  transform: scale(1);
}

#___reactour .reactour__dot--is-active:before {
  color: #6EBB2A;
}

[data-tour-elem="controls"] {
  display: flex;
}

[data-tour-elem="navigation"] {
  order: -1;
  margin-right: auto;
}

[data-tour-elem="left-arrow"] span,
[data-tour-elem="right-arrow"] span {
  width: 67px;
  display: flex;
  align-items: center;
  color: #fff;
  height: 32px;
  padding: 9px;
  border-radius: 40px;
}

#___reactour [data-tour-elem="left-arrow"]:hover,
#___reactour [data-tour-elem="right-arrow"]:hover {
  color: #fff;
}

[data-tour-elem="left-arrow"] span svg {
  transform: rotate(-180deg);
  margin-right: 10px;
  fill: #6EBB2A;
}

[data-tour-elem="left-arrow"] span path {
  stroke: #6EBB2A;
}

#___reactour [data-tour-elem="left-arrow"] {
  margin-right: 0;
}

#___reactour [data-tour-elem="right-arrow"] {
  margin-left: 10px;
}

[data-tour-elem="right-arrow"] span {
  justify-content: center;
  background-color: #6EBB2A;
}

[data-tour-elem="right-arrow"] span svg {
  margin-left: 10px;
}