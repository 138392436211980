.onollo-checkbox input {
  display: none;
  opacity: 0;
}

.onollo-checkbox {
  flex-shrink: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  position: relative;
  cursor: pointer;
  background: #ffffff;
}

.onollo-checkbox:after {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: var(--color-green);
  border: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.onollo-checkbox--checked:after {
  content: '';
}
.onollo-checkbox--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.onollo-checkbox--theme-black {
  border-color: #000;
}

.onollo-checkbox--theme-black:after {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: #000;
}
