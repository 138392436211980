:root {
  --base-background: rgba(253, 253, 253, 1);
  --grey-background: #f3f3f3;
  --test: #fff;
  --color-content: #445b64;
  --color-content-light: rgba(122, 149, 164, 1);
  --color-dark: #384144;
  --color-dark-gray: rgb(53, 58, 55);
  --color-light-dark: #4f5e63;
  --color-light-accent: rgba(65, 176, 191, 0.5);
  --color-accent: #41b0bf;
  --color-accent-pink: #ee7d7a;
  --color-light-green: rgba(45, 170, 90, 0.5);
  --color-green: #2aad5b;
  --color-light-danger: rgba(255, 86, 97, 0.5);
  --color-danger: #ff5661;
  --color-warning: #b99918;
  --color-blue: #2b3847;

  --color-facebook: #4267b2;
  --color-twitter: #1da1f2;
  --color-linkedin: #197fb7;
  --color-instagram: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  --color-pinterest: #bd081c;

  --color-light-attention: rgb(254, 231, 163);
  --color-attention: rgb(153, 120, 85);

  --color-border: #c3cad2;
  --color-border--active: #8997a7;
  --color-content-border: #e4eaed;
  --color-border-grey: #e5eaed;
  --color-border-main: #000;

  --max-width: 100%;
  --min-width: 100%;
  --default-form-width: 524px;

  --twitter-main-color: #1da1f3;
  --facebook-main-color: #4267b2;
  --pinterest-main-color: #bd191d;

  --color-base: #4e5a6a;
  --color-base-light: #b1b9c2;
  --color-link: #41b0bf;
}
