.bfm {
  overflow: hidden;
}

.bfm__flex {
  display: flex;
}

.bfm__left {
  width: 50%;
  background: #1c182f;
  color: #fff;
}

.bfm__right {
  width: 50%;
  padding: 50px 82px 39px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1d1831;
  position: relative;
}

.bfm__close {
  font-size: 0;
  position: absolute;
  right: 12px;
  top: 12px;
}

.bfm__logo {
  position: relative;
  margin: -25px 0;
}

.bfm__logo-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.bfm__logo-title {
  font: 600 14px/17px 'Work Sans', sans-serif;
  text-shadow: 0px 0px 5.40798px #cea5ff;
  letter-spacing: 0.1em;
  margin-bottom: 7px;
}

.bfm__logo-desc {
  font: 400 10px/11px 'Work Sans', sans-serif;
  color: #fcfcfc;
}

.bfm__features-wrp {
  padding: 0 74px 64px;
}

.bfm__features-title {
  font: 600 20px/20px 'Work Sans', sans-serif;
  margin-bottom: 17px;
}

.bfm__features {
  padding-left: 14px;
  margin-left: 5px;
}

.bfm__feature {
  list-style-type: disc;
  font-size: 14px;
}

.bfm__feature_disc-plus {
  list-style-type: none;
  position: relative;
}

.bfm__feature_disc-plus:before {
  content: '+';
  display: block;
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}

.bfm__feature:not(:last-child) {
  margin-bottom: 13px;
}

.bfm__period {
  font-size: 18.1123px;
  margin-bottom: 5px;
}

.bfm__name {
  font: 600 32px/38px 'Work Sans', sans-serif;
  background-image: linear-gradient(89.55deg, #5b0eff 0.29%, #cf0eff 103.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.bfm__desc {
  font: 400 16px/25px 'Work Sans', sans-serif;
  margin: 40px 0;
}

.bfm__prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.bfm__prices-label {
  font: 400 17.44px/20px 'Work Sans', sans-serif;
  margin-bottom: 26px;
}

.bfm__prices-past {
  font: 500 22px/26px 'Work Sans', sans-serif;
  letter-spacing: 0.05em;
  text-decoration: line-through;
}

.bfm__prices-current {
  font: 600 40px/39px 'Work Sans', sans-serif;
}

.bfm__btn {
  min-width: 120px;
}

.bfm__date {
  font: 600 12px/14px 'Work Sans', sans-serif;
  margin-top: 10px;
}

.bfm_theme_christmas .bfm__logo {
  margin: 30px auto 0;
  text-align: center;
}

.bfm_theme_christmas .bfm__logo-info {
  display: none;
}

.bfm_theme_christmas .bfm__left {
  background-color: #00261b;
}

.bfm_theme_christmas .bfm__right {
  padding: 50px 54px 39px;
}

.bfm_theme_christmas .bfm__prices-current {
  color: #ff0000;
}

.bfm_theme_christmas .bfm__name {
  background-image: linear-gradient(89.55deg, #ff0000 0.29%, #209f54 103.47%);
}

.bfm_theme_christmas .bfm__btn {
  background-color: #00261b;
}

@media (max-width: 750px) {
  .bfm__flex {
    flex-direction: column;
  }

  .bfm__left,
  .bfm__right {
    width: 100%;
  }

  .bfm__right {
    padding: 30px 40px;
  }

  .bfm__logo {
    text-align: center;
  }

  .bfm__features-wrp {
    padding: 0 40px 30px;
  }

  .bfm_theme_christmas .bfm__logo {
    margin-top: 15px;
  }
}
