.accordion-list {
  width: 100%;
  border-radius: 6px;
}

.accordion-item {
  border: 1px solid var(--color-border);
  margin-bottom: 10px;
  border-radius: 6px;
}

.accordion-item.accordion-item__without-body {
  border: 1px solid var(--color-content-border);
}

.accordion-item--hidden {
  border: 1px solid var(--color-content-border);
}

.accordion-item--hidden:not(.accordion-item__without-body):hover {
  border: 1px solid var(--color-border);
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-border);
  cursor: pointer;
}
.accordion-item__without-body .accordion-title {
  border-bottom: 0;
  cursor: initial;
}
.accordion-title--closed {
  border-bottom: 0;
}

.accordion-item__without-body .accordion-title--closed {
  min-height: 55px;
}

.accordion-body {
  padding: 20px;
}
