.visible-md,
.visible-sm,
.visible-xs {
    display: none;
}
.visible-lg {
    display: block;
}

@media screen and(max-width: 959px) {

    .hidden-md {
        display: none !important;
    }
    .visible-md {
        display: block;
    }
    .center {
        max-width: 90%;
    }
    .md-center {
        max-width: 768px;
    }
    .md-center100p {
        max-width: 100%;
    }

}

@media screen and(max-width: 767px) {

    .hidden-sm {
        display: none !important;
    }
    .visible-sm {
        display: block;
    }
    .sm-center {
        max-width: 480px;
    }
    .sm-center100p {
        max-width: 100%;
    }

}

@media screen and(max-width: 479px) {

    .hidden-xs {
        display: none !important;
    }
    .visible-xs {
        display: block;
    }
    .xs-center100p {
        max-width: 100%;
    }

}