.Toastify__toast {
  border-radius: 5px;
}
.Toastify__toast_hide {
  display: none !important;
}
.Toastify__toast--success {
  background-color: var(--color-green);
}
.Toastify__toast--error {
  background-color: var(--color-danger);
}

.Toastify__toast--warn {
  background-color: var(--color-warning);
}
.Toastify__toast-body {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.Toastify__toast-container--bottom-left {
  left: 0;
  bottom: 0;
  padding: 0;
}

.sync-product-notification {
  margin-bottom: 0;
  min-height: auto;
  border-radius: 5px 5px 0 0;
  max-width: 455px;
}

.sync-product-notification .Toastify__toast-body {
  font-size: 16px;
  margin: 0;
  text-align: left;
}
