@supports not (--test: #fff) {
  .modal__info {
    color: #445b64;
  }
}

.modal__content {
  padding: 0 66px;
}

.modal__content_theme_dark {
  padding: 50px 45px;
  color: #000;
}

.modal__content_theme_dark .h2 {
  padding: 0 !important;
  margin-bottom: 29px !important;
  text-align: center;
  margin-bottom: 8px;
  font: 700 22px/30px 'AvenirNext';
}

.modal__content_theme_dark .h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content_theme_dark .h2 img {
  flex-shrink: 0;
  margin-left: 25px;
}

.modal__content_theme_dark .modal__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.modal__content_theme_dark .modal__actions .btn:not(:last-child) {
  margin-right: 20px;
}

.modal__content .h2,
.onollo__modal .h2 {
  padding: 0 15px;
  text-align: center;
  word-break: break-word;
}

.onollo__modal_flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 30px 0;
}

.onollo__modal_flex > form,
.onollo__modal_flex__left {
  padding: 0 50px 0 0;
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.onollo__modal .onollo__modal_flex__left_top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.onollo__modal_flex > form,
.onollo__modal_flex__right {
  width: 50%;
  max-width: 560px;
}

.onollo__modal_flex__left_bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.onollo__modal_flex__left_bottom .input__wrapper {
  width: calc(100% - 96px);
  margin-bottom: 20px;
  text-align: left;
}

.onollo__modal_flex__left_bottom .post_modal__textarea_wrp {
  margin: 0 0 20px;
}

.onollo__modal_flex > form input,
.onollo__modal_flex__left input {
  margin: 0;
}

.onollo__modal_flex .modal__manipulation {
  width: 100%;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #c3cad2;
  justify-content: flex-end;
}

.onollo__modal_flex .modal__schedule_wrp .btn {
  width: 200px;
}

.onollo__modal_flex .modal__schedule_wrp .btn span {
  white-space: nowrap;
}

.onollo__modal_flex .modal__schedule_wrp + button {
  width: 200px !important;
  flex-grow: 0;
}

.modal__info {
  color: var(--color-content);
  max-width: 750px;
  margin: 5px auto 0;
  text-align: center;
  line-height: 23px;
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
}

.modal__info-large {
  line-height: 1.3;
  font-size: 24px;
  font-weight: 600;
}

.modal__manipulation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 30px;
  border-top: 1px solid #d7dfe3;
  padding: 24px 66px 0 66px;
}
.modal__manipulation_center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal__manipulation > button:nth-child(1),
.modal__manipulationInner > button:nth-child(1) {
  flex-shrink: 0;
}

.modal__manipulation > button:nth-child(2),
.modal__manipulationInner > button:nth-child(2) {
  margin-left: 20px;
}

.modal__manipulation--single-btn button:nth-child(1) {
  width: 100%;
}

.modal__schedule_btn {
  width: 100%;
  margin-top: 20px;
}

.modal__schedule_wrp {
  flex-grow: 0;
  position: relative;
}

.modal__schedule_wrp {
  flex-grow: 0;
  position: relative;
}

.modal__manipulationInner {
  display: flex;
  justify-content: flex-end;
}

.modal__schedule_wrp + button {
  width: auto !important;
  flex-grow: 2;
}

.modal__schedule_wrp .modal__schedule_btn {
  margin: 0;
  width: 200px;
  background: var(--color-blue);
}

.modal__schedule_wrp .modal__scheduled_btn {
  padding-right: 40px;
}

.modal__manipulation .modal__schedule_cancel {
  margin: 0;
  background: none;
  border: none;
  font-size: 0;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' width='19px' height='21px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.642 15.642' xmlns:xlink='http://www.w3.org/1999/xlink' enable-background='new 0 0 15.642 15.642'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061 c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061 l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541 l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s;
}

.modal__manipulation .modal__schedule_cancel:disabled {
  cursor: no-drop;
}

.modal__manipulation .modal__schedule_cancel:not(:disabled):hover {
  opacity: 0.5;
}

.onollo__modal_title__flex {
  border-bottom: 1px solid #c3cad2;
  padding-bottom: 30px;
  margin: 0 30px;
  position: relative;
  margin-bottom: 5px;
}

.onollo__modal_title__flex .btn__how_item_works {
  position: absolute;
  bottom: 33px;
  left: 15px;
}

.ol_with-number {
  list-style: decimal;
  text-align: left;
}
.ol_with-number li {
  list-style-type: decimal;
}
.ol_with-number b {
  font-weight: bold;
}
.ol_with-number em {
  font-style: italic;
}
.copy {
  cursor: pointer;
}
.copy:hover {
  opacity: 0.9;
}
.copy::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 15px;
  background-image: url('../images/icons/copy.svg');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.85;
}

@media (max-width: 1020px) {
  .onollo__modal_flex {
    flex-direction: column;
  }

  .onollo__modal_flex__left,
  .onollo__modal_flex__right {
    width: 100%;
  }

  .onollo__modal_flex__right {
    max-width: 100%;
    margin-top: 30px;
  }

  .onollo__modal_title__flex .btn__how_item_works {
    display: none;
  }
}

@media (max-width: 526px) {
  .modal__manipulation,
  .modal__manipulationInner {
    width: 100%;
    flex-direction: column-reverse;
  }
  body .onollo__modal_flex .modal__schedule_wrp .btn,
  body .modal__manipulation .btn,
  .add-account__btns .btn {
    width: 100% !important;
  }
  .modal__manipulation > button:nth-child(2),
  .modal__manipulationInner > button:nth-child(2) {
    margin: 0 0 20px;
  }

  .onollo__modal_flex__left_bottom .input__wrapper {
    width: calc(100% - 39px);
  }
}

@media (max-width: 480px) {
  .onollo__modal_flex {
    padding: 15px;
  }
  .modal__content,
  .add-account__wrapper {
    padding: 25px !important;
  }
  .modal__content .h2 {
    font: 700 35px/33px 'Work Sans', sans-serif;
  }
  .ol_with-number b {
    word-break: break-all;
  }
}
