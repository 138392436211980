.slick-slider .slick-arrow {
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background: url('../../../assets/images/icons/arrow-left.svg') no-repeat !important;
  width: 19px;
  height: 28px;
}

.slick-slider .slick-arrow:before {
  content: none;
}
.slick-slider .slick-prev {
  left: 6px;
}
.slick-slider .slick-next {
  right: 6px;
  transform: translateY(-50%) rotate(180deg);
}

.slick-slider .slick-dots {
  bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slider .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0;
}
.slick-slider .slick-dots li button {
  width: 5px;
  height: 5px;
  padding: 5px;
}
.slick-slider .slick-dots li button:before {
  width: 5px;
  height: 5px;
  font-size: 6px;
  line-height: 10px;
  left: 2px;
  opacity: 1;
  color: var(--color-content-border);
}
.slick-slider .slick-dots li.slick-active button:before {
  color: var(--color-green);
}

.slick-slider .slick-slide img {
  border-radius: 5px;
}
