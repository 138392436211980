.spy-feed__filters {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.spy-feed__socials {
  display: flex;
  margin-left: 31px;
}

.spy-feed__social {
  font-size: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.spy-feed__social:not(:last-of-type) {
  margin-right: 15px;
}

.spy-feed__social_active {
  border: 1px solid #000;
}

.spy-feed__social img {
  width: 30px;
  height: 30px;
}

.spy-feed__filters-select-wrp {
  min-width: 151px;
}

.spy-feed__post {
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in-out;
}

.spy-feed__list {
  position: relative;
}

.spy-feed__list_loading:before {
  content: '';
  display: block;
  width: 100%;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.spy-feed__list-items {
  overflow: hidden;
  position: relative;
}

.spy-feed__progress :global(.onollo__loader) {
  height: auto !important;
}

.spy-feed__progress {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 76px 0;
}

.spy-feed__progress-title {
  color: #000;
  margin-bottom: 12px;
}

.spy-feed__actions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.spy-feed__actions .spy-feed__progress {
  transform: translateY(100%);
}

.spy-feed__action {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  margin: 5px 0;
}

.spy-feed__action:disabled {
  cursor: no-drop;
  opacity: 0.7;
}

.spy-feed__action-load-icon {
  animation: rotation 1.5s infinite linear;
}

.spy-feed__day-wrp {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

.spy-feed__day-wrp:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.spy-feed__day {
  display: inline-block;
  font: 400 16px 'AvenirNext', sans-serif;
  background-color: #fdfdfd;
  padding: 5px 10px;
  color: #797979;
  position: relative;
  z-index: 3;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .spy-feed__filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .spy-feed__socials {
    margin: 15px 0 0;
  }

  .spy-feed__filters-select-wrp {
    min-width: 100%;
  }

  .spy-feed__list_loading:before {
    height: 103px;
  }
}
