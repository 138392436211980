.header {
  font-size: 32px !important;
  margin-top: 20px;
}

.upgrade-plan {
  padding-top: 20px;
  position: relative;
}

.upgrade-plan::before {
  content: url("assets/images/dino.svg");
  position: absolute;
  top: 0;
}

.upgrade-plan__text {
  text-align: center;
  font: 400 16px/27px 'AvenirNext', sans-serif;
}

.upgrade-plan__text a {
  text-decoration: underline;
}

.upgrade-plan__text a:hover {
  text-decoration: none;
}

.upgrade-plan__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.upgrade-plan__actions > :global(.btn):last-child {
  text-decoration: underline;
}

.free-plan__accent {
  color: #ED7200;
}

.starter-button__accent {
  color: #589885;
}