/*
    if variables not supported write original values
*/
@supports not (--test: #fff) {
  body {
    min-width: 980px;
    color: #445b64;
    background: rgba(253, 253, 253, 1);
  }

  ::selection {
    background: #445b64;
  }

  .center {
    max-width: 1210px;
    min-width: 980px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif !important;
}

body.dragging,
body.dragging * {
  cursor: grabbing !important;
}

body {
  position: relative;
  font: 400 17px/18px 'Work Sans', sans-serif;
  color: var(--color-content);
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  background: var(--base-background);
}

body::-webkit-scrollbar-thumb {
  background-color: #e4eaed;
  border-radius: 6px;
}

body::-webkit-scrollbar {
  width: 16px;
  background-color: #f6f5fc;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}

:focus,
:active {
  outline: none;
}

::selection {
  color: #fff;
  background: var(--color-content);
}

img {
  border: 0;
  position: relative;
  vertical-align: top;
  max-width: 100%;
}

li {
  list-style: none;
  position: relative;
}

.center {
  margin: 0 auto;
  max-width: var(--max-width);
  min-width: var(--min-width);
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

/*
-----------------------------------------------------------------------------*/
.clr:after {
  clear: both;
  content: '';
  display: table;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.notsel {
  user-select: none;
}

.h1 {
  font-size: 52px;
  line-height: 62px;
  font-weight: bold;
}

.h2 {
  font: 700 42px/49px 'Work Sans', sans-serif;
  color: var(--color-dark);
}

.h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
}

.h4 {
  font-weight: bold;
  font-size: 21px;
}

hr {
  height: 1px;
  border: 0;
  background: rgba(137, 151, 167, 0.1);
}

.text_normal {
  font-weight: 400;
}

main,
header,
footer,
section,
article,
aside,
time {
  display: block;
  position: relative;
}

.item_new,
.item_beta {
  flex-shrink: 0;
  display: inline-block;
  position: relative;
}

.item_new::after,
.item_beta::after {
  top: -20px;
  right: -25px;
  position: absolute;
  border-radius: 10px;
  padding: 0 4px;
  text-align: center;
  background-color: rgba(238, 125, 122, 0.5);
  font-size: 10px;
  font-weight: 500;
  line-height: 1.5;
  pointer-events: none;
}

.item_new::after {
  content: 'new';
}

.item_beta::after {
  content: 'beta';
}

/* form
-----------------------------------------------------------------------------*/

.checkbox {
  width: 18px;
  height: 18px;
  position: relative;
}

.checkbox i {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
  top: 0;
  left: 0;
  background: url('../images/sprite.svg') no-repeat -30px -101px;
}

.checkbox.check i {
  background: url('../images/sprite.svg') no-repeat -78px -101px;
}

.checkbox input {
  opacity: 0;
  position: absolute;
  left: 9999px;
}

.double-field--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 479px) {
  .double-field--wrapper {
    flex-direction: column;
  }
}

.double-field--item {
  margin-top: 20px;
}

.double-field--item .react-datepicker-wrapper,
.double-field--item .react-datepicker__input-container {
  width: 100%;
}

.double-field--item,
.double-field--wrapper .select2-container {
  width: calc(50% - 10px);
}

@media (max-width: 479px) {
  .double-field--item,
  .double-field--wrapper .select2-container {
    width: 100%;
  }
}

/* flex
-----------------------------------------------------------------------------*/
.flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flexbox--center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flexbox_wrap {
  flex-wrap: wrap;
}
.flexbox--justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flexbox--align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.store-buttons {
  flex-direction: column;
}

.store-buttons button {
  min-width: 270px;
}

.store-buttons button span {
  width: 100%;
}

/* width
-----------------------------------------------------------------------------*/
.w50p {
  width: 50%;
}

.w100p {
  width: 100%;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
  display: none !important;
}

.hidden {
  overflow: hidden;
}

.hidden-visible {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -1;
  overflow: hidden;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

main {
  overflow: hidden;
}

/* margin
-----------------------------------------------------------------------------*/
.mt-20 {
  margin-top: 20px;
}

.mr0 {
  margin-right: 0;
}
