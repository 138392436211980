@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/worksans-regular/worksans-regular.woff2') format('woff2'),
    url('../fonts/worksans-regular/worksans-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/worksans-medium/worksans-medium.woff2') format('woff2'),
    url('../fonts/worksans-medium/worksans-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/worksans-semibold/worksans-semibold.woff2') format('woff2'),
    url('../fonts/worksans-semibold/worksans-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/worksans-bold/worksans-bold.woff2') format('woff2'),
    url('../fonts/worksans-bold/worksans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Regular/AvenirNext-Regular.woff2') format('woff2'),
    url('../fonts/AvenirNext-Regular/AvenirNext-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Bold/AvenirNext-Bold.woff2') format('woff2'),
    url('../fonts/AvenirNext-Bold/AvenirNext-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextCyr';
  src: url('../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff2') format('woff2'),
    url('../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextCyr';
  src: url('../fonts/AvenirNextCyr-Demi/AvenirNextCyr-Demi.woff2') format('woff2'),
    url('../fonts/AvenirNextCyr-Demi/AvenirNextCyr-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
