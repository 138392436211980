@supports not (height: 100vh) {
  .page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(253, 253, 253, 1);
  }
}

@supports not (--test: #fff) {
  .page {
    background: rgba(253, 253, 253, 1);
  }
}

.page {
  min-height: 100vh;
  background: var(--base-background);
}

.page_text-color_black {
  color: #000;
}

.page > article {
  padding-top: 51px;
  padding-bottom: 40px;
}

.page-flex {
  display: flex;
}

.page-flex__right {
  flex-grow: 1;
  padding: 32px;
}

.page_default-font-color_black {
  color: #000;
}

@media (max-width: 780px) {
  .page-flex {
    flex-direction: column;
  }
}

.toggle-button__wrapper {
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggle-button__wrapper > button {
  border: 0;
  outline: none;
  margin-left: 20px;
  color: var(--color-blue);
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  transition: color ease-in-out 0.3s;
  background: transparent;
}

.toggle-button__wrapper > button:hover {
  color: var(--color-accent);
}

.tr-link {
  cursor: pointer;
}

.tr-link:hover {
  background-color: var(--color-content-border);
}

.Toastify__toast-container--bottom-left {
  bottom: 100px;
}

.container {
  padding: 30px 78px;
}

@media (max-width: 1100px) {
  .container {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 30px 15px;
  }
}
