.cloud {
  color: #000;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  overflow: hidden;
}

.cloud__flex {
  display: flex;
}

.cloud__left {
  flex-shrink: 0;
  width: 130px;
  background-color: #f2f4f4;
  padding: 19px 30px;
}

.cloud__right {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.cloud__logo {
  display: flex;
  align-items: center;
  margin-bottom: 67px;
}

.cloud__logo-img {
  width: 30px;
  height: 30px;
}

.cloud__logo-text {
  margin-left: 10px;
  font-size: 24px;
}

.cloud__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cloud__close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 3;
}

.cloud__nav-item {
  font-size: 14px;
  margin-bottom: 34px;
  color: #000;
}

.cloud__nav-item_active {
  font-weight: bold;
}

.cloud__list-wrp {
  padding: 0 50px 25px;
  min-height: 631px;
  max-height: 631px;
  overflow-y: auto;
  position: relative;
}

.cloud__list-wrp::-webkit-scrollbar {
  display: inherit;
}

.cloud__list-wrp::-webkit-scrollbar {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 10px;
  background-color: #f6f5fc;
}

.cloud__list-wrp::-webkit-scrollbar-track {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #f6f5fc;
}

.cloud__list-wrp::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #e4eaed;
}

.cloud__title-wrp {
  padding: 25px 50px 43px;
}

.cloud__title {
  font: 600 24px/28px 'Work Sans', sans-serif;
  margin-bottom: 15px;
}

.cloud__count {
  font: 400 13px/15px 'Work Sans', sans-serif;
  font-style: italic;
}

.cloud__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.cloud__media {
  width: 115px;
  height: 115px;
  margin: 0 10px 20px;
  border: 1px solid transparent;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
}

.cloud__media_mode_selected {
  border-color: #589885;
}

.cloud__media_mode_view {
  width: 100%;
  height: 250px;
}

.cloud__media_mode_view:not(.cloud__media_mode_selected) {
  border-color: #d6d6d6;
}

.cloud__media_mode_view .cloud__media-info :global {
  z-index: 3;
  flex-grow: 1;
  position: static;
  animation: 0.4s csm_fadeIn ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}

.cloud__media_mode_view .cloud__media-thumb {
  flex-shrink: 0;
  width: 50%;
  transition: all 0.4s ease-in-out;
}

.cloud__media-thumb {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f6f6f6;
}

.cloud__media-check {
  width: 16px;
  height: 15px;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 3;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}

.cloud__media-check_checked {
  background-color: #bcd6ce;
  border-color: #589885;
  background-image: url(assets/images/icons/green-check.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.cloud__media-view-box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.cloud__media-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.cloud__media-thumb:hover .cloud__media-view-box {
  z-index: 2;
  opacity: 1;
}

.cloud__media-view-btn {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 2px 20px;
  font: 500 12px/14px 'Work Sans', sans-serif;
  color: #fff;
  position: relative;
  z-index: 2;
}

.cloud__actions {
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  position: relative;
}

.cloud__selected-info span {
  margin-right: 11px;
}

.cloud__media-info {
  position: relative;
  z-index: -1;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.25s ease-in-out;
}

.cloud__media-info-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 27px 53px;
  word-break: break-word;
}

.cloud__media-info-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.cloud__media-info-text {
  font-size: 12px;
  line-height: 14px;
}

.cloud__media-info-footer {
  display: flex;
  padding: 13px 17px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
}

.cloud__media-info-close {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #909090;
  font-size: 12px;
}

.cloud__media-info-close img {
  margin: -2px 5px 0 0;
}

.cloud__action-add {
  height: auto;
  line-height: 1;
  padding: 13px 30px;
}

.cloud__media-video {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.cloud__list-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes :global(csm_fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@media (max-width: 830px) {
  .cloud {
    margin: 0 15px;
  }

  .cloud__flex {
    flex-direction: column;
  }

  .cloud__left {
    width: 100%;
    padding: 15px 30px 15px 15px;
  }

  .cloud__logo {
    margin-bottom: 20px;
  }

  .cloud__nav {
    flex-direction: row;
  }

  .cloud__nav-item {
    margin: 0 15px 0 0;
  }

  .cloud__title-wrp {
    padding: 15px 15px 20px;
  }

  .cloud__list-wrp {
    min-height: 300px;
    max-height: 300px;
  }

  .cloud__list-wrp {
    padding: 0 15px 20px;
  }

  .cloud__actions {
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .cloud__selected-info {
    margin-bottom: 15px;
    align-self: center;
  }

  .cloud__action-add {
    width: 100%;
  }

  .cloud__media-info-content {
    padding: 15px;
  }
}
