.upgrade-plan-popover {
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  background: none !important;
  border: none !important;
  z-index: 1001 !important;
}

.upgrade-plan-popover:before,
.upgrade-plan-popover:after {
  display: none;
}

.upgrade-plan-popover.show {
  opacity: 1 !important;
}
