.upgrade-plan {
  width: 277px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(134, 134, 134, 0.25);
  border-radius: 5px;
  padding: 20px 17px 10px;
  text-align: center;
  font-family: 'AvenirNext', sans-serif;
  color: #000;
}

.upgrade-plan__title {
  font: 600 16px 'AvenirNext', sans-serif;
  margin-bottom: 10px;
}

.upgrade-plan__desc {
  font: 400 13px/27px 'AvenirNext', sans-serif;
  margin-bottom: 20px;
}

.upgrade-plan__actions :global(.btn) {
  width: 100%;
}
