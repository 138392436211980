.upgrade-plan__text {
  text-align: center;
  font: 400 16px/27px 'AvenirNext', sans-serif;
}

.upgrade-plan__text a {
  text-decoration: underline;
}

.upgrade-plan__text a:hover {
  text-decoration: none;
}

.upgrade-plan__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.upgrade-plan__actions > :global(.btn):last-child {
  text-decoration: underline;
}
