.timer {
  display: flex;
  justify-content: center;
}

.time_unit-wrapper {
  text-align: center;
  position: relative;
}

.time_unit {
  background-color: #F1F1F1;
  border-radius: 8px;
  padding: 40px 20px;
  margin: 0 10px;
  font-size: 40px;
  font-weight: 600;
  width: 80px;
}

.time_unit-label {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}