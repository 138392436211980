.datetime-picker {
  position: relative;
  border: none;
  background-color: var(--color-dark);
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  color: #4a4a4a;
  width: 250px;
  box-sizing: content-box;
  z-index: 100;
}

.datetime-picker .calendar {
  margin-top: 0;
  border-radius: 0;
  border: 0;
}
.datetime-picker .calendar .calendar-nav {
  display: flex;
  justify-content: space-between;
  border: 0;
  min-height: 32px;
  padding: 4px;
  border-radius: 0;
}
.datetime-picker .calendar .calendar-nav button {
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url('../../images/arrow-white.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.datetime-picker .calendar .calendar-nav button.next-month {
  transform: rotate(180deg);
}
.datetime-picker .calendar .calendar-nav button:hover {
  background-color: var(--color-green);
}
.datetime-picker .calendar .calendar-nav .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 6px;
  color: #ffffff;
  cursor: pointer;
}
.datetime-picker .calendar .calendar-nav .current-date:hover {
  background-color: var(--color-green);
}
.datetime-picker .calendar .calendar-nav .current-date.disabled {
  cursor: default;
}
.datetime-picker .calendar table {
  display: block;
  margin: 4px;
}
.datetime-picker .calendar table th,
.datetime-picker .calendar table td {
  padding: 0;
}
.datetime-picker .calendar table thead {
  display: block;
  margin: 8px 0 3px;
}
.datetime-picker .calendar table thead tr {
  display: flex;
  flex-wrap: wrap;
}
.datetime-picker .calendar table thead th {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
}
.datetime-picker .calendar table tbody {
  display: block;
}
.datetime-picker .calendar table tbody tr {
  display: flex;
  flex-wrap: wrap;
}
.datetime-picker .calendar table tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}
.datetime-picker .calendar table tbody tr td:hover {
  background-color: var(--color-green);
}
.datetime-picker .calendar table tbody tr td.prev,
.datetime-picker .calendar table tbody tr td.next {
  color: #dedede;
}
.datetime-picker .calendar table tbody tr td.disabled {
  color: rgba(255, 255, 255, 0.2);
}
.datetime-picker .calendar table tbody tr td.prev:hover,
.datetime-picker .calendar table tbody tr td.next:hover {
  color: #c5c5c5;
}

.datetime-picker .calendar table tbody tr td.disabled:hover {
  color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
  cursor: not-allowed;
}
.datetime-picker .calendar table tbody tr td.now {
  color: var(--color-green);
  border: 1px solid var(--color-green);
}
.datetime-picker .calendar table tbody tr td.now:hover {
  color: #fff;
  border: 0;
}
.datetime-picker .calendar table tbody tr td.selected {
  background-color: var(--color-green);
  color: #fff;
}
.datetime-picker .calendar table tbody tr td.selected:hover {
  background-color: var(--color-green);
  color: #fff;
}
.datetime-picker .calendar table tbody tr td.selected.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.datetime-picker .calendar table tbody tr td.selected.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.datetime-picker .calendar table tbody tr td.selected.start.end {
  border-radius: 4px;
}
.datetime-picker .calendar table tbody tr td.between {
  background-color: #f5fbfe;
  border-radius: 0;
}
.datetime-picker .calendar .calendar-days table tbody tr td {
  width: 14.28571429%;
}
.datetime-picker .calendar .calendar-months table tbody tr td {
  width: 33.33333333%;
  height: 40px;
}
.datetime-picker .calendar .calendar-years table tbody tr td {
  width: 25%;
  height: 60px;
}
.datetime-picker .time {
  padding: 4px;
  display: flex;
  align-items: center;
  position: relative;
}
.datetime-picker .time .show-time {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  min-width: 100%;
  margin-bottom: 4px;
}
.datetime-picker .time .show-time input {
  border: none;
  background: none;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  font-size: 24px;
  font-family: 'Work Sans', CenturyGothic, AppleGothic, sans-serif;
  color: #fff;
  position: relative;
  z-index: 1;
  text-align: center;
}

.datetime-picker .time .show-time input:hover,
.datetime-picker .time .show-time input:focus {
  background: #2AAD5B;
}
.datetime-picker .time .show-time .separater {
  margin: 0 2px 3px;
  line-height: 1;
}
.datetime-picker .time .sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}
.datetime-picker .time .sliders .slider-text {
  display: none;
}
.datetime-picker .time .sliders .slider {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background-image: linear-gradient(to right, #dedede, #dedede);
  background-position: 0 50%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
}
.datetime-picker .time .sliders .slider .handle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  cursor: pointer;
}
.datetime-picker .time .sliders .slider .handle:hover {
  border-color: #b8b8b8;
}
.datetime-picker .time .sliders .slider .handle:focus,
.datetime-picker .time .sliders .slider .handle:active {
  background-color: var(--color-green);
  border-color: var(--color-green);
}
.datetime-picker .shortcuts-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 8px;
}
.datetime-picker .shortcuts-bar .btn {
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 15px 'Work Sans', sans-serif;
  text-align: center;
  color: #fff;
  height: initial;
  transition: opacity 0.5s;
  text-decoration: none;
}
.datetime-picker .shortcuts-bar .btn:hover {
  border: 1px solid var(--color-green);
  border-radius: 5px;
  background: var(--color-green);
  color: #fff;
}
.datetime-picker .shortcuts-bar .btn:not(:first-child) {
  margin-left: 5px;
}
.datetime-range-picker {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: content-box;
  z-index: 100;
}
.datetime-trigger {
  position: relative;
}
.datetime-trigger .datetime-picker {
  position: absolute;
  top: 100%;
}
.datetime-range-trigger {
  position: relative;
}
.datetime-range-trigger .datetime-range-picker {
  position: absolute;
  top: 100%;
}
.datetime-picker-popup,
.datetime-range-picker-popup {
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: '';
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
  left: 10px;
  top: -6px;
  transform: rotate(315deg);
}
.datetime-picker .panel-nav {
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid #efefef;
}
.datetime-picker .panel-nav + div {
  clear: both;
}
.datetime-picker .panel-nav button {
  height: 30px;
  padding: 2px 10px;
  margin: 0 10px;
  border: 1px solid var(--color-content);
  border-radius: 5px;
  background: #fff;
  color: var(--color-dark);
  cursor: pointer;
}
.datetime-picker .panel-nav button:hover,
.datetime-picker .panel-nav button.active {
  border: 1px solid var(--color-green);
  background: var(--color-green);
  color: #ffffff;
}

.className-for-animate-time-change .datetime-picker .time .sliders .slider .handle {
  transition: all ease-in 0.25s;
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: none;
  display: none;
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: none;
  display: none;
}

.datetime-picker__done {
  width: 100% !important;
  height: 42px;
  line-height: 42px;
  border-radius: 0 0 4px 4px;
}

.datetime-picker--trigger-up .datetime-picker {
  top: -20px !important;
  transform: translateY(-100%);
}

.datetime-picker__smart_schedule {
  min-height: 68px;
  padding: 12px 27px;
  border-top: 1px solid #979797;
}

.datetime-picker__smart_schedule_desc {
  text-align: center;
  color: #fff;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 8px;
}

.datetime-picker__smart_schedule_btn {
  text-align: center;
  padding: 1px 10px;
  border-radius: 30px;
  font-size: 12px;
  color: #000;
  background-color: #D7D7D7;
}

.datetime-picker__smart_schedule_btn.active {
  background-color: var(--color-green);
  color: #fff;
}

.datetime-picker__smart_schedule .slick-slide {
  padding: 0 4px;
}

.datetime-picker__smart_schedule .slick-slider .slick-prev,
.datetime-picker__smart_schedule .slick-slider .slick-next {
  top: 50%;
  transform: translateY(-50%);
}

.datetime-picker__smart_schedule .slick-slider .slick-prev {
  left: -15px;
}

.datetime-picker__smart_schedule .slick-slider .slick-next {
  right: -15px;
  transform: translateY(-50%) rotate(-180deg);
}