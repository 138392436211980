.tooltip {
    background: #ffffff;
    border-radius: 5px;
    padding: 7px 5px;
    box-shadow: 0 0 24px rgba(37,38,94, 0.1);
}

.tooltip_template {
    margin-left: 0 !important;
    max-width: 250px;
    max-height: 150px;
    word-break: break-all;
    overflow-x: auto;
}
.tooltip_template li {
    list-style: inherit;
    list-style-type: inherit;
}

.tooltip_template ul {
    list-style-type: disc;
    list-style-position: inside
}

.tooltip_template ol {
    list-style-type: decimal;
    list-style-position: inside
}
.tooltip_template i{
    display: inline-block;
    margin-bottom: 1px;
    background-color: #eee;
    padding: 2px 2px 0 2px;
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
}
.tooltip_template i+i{
    margin-left: 2px;
}


