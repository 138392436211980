.onollo__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.onollo__loader img {
  display: block;
}
