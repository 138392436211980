.select-stores__text {
  text-align: center;
  font: 400 16px/27px 'AvenirNext', sans-serif;
}

.select-stores__list {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 15px;
  height: 150px;
  margin-top: 15px;
  overflow-y: auto;
}

.select-stores__item {
  padding: 12px 15px;
  border: 1px solid #000;
  border-radius: 8px;
  width: 100%;
  transition: all 0.25s ease;
  margin-bottom: 10px;
}

.select-stores__item_selected {
  color: #fff;
  background-color: #589885;
  border-color: #589885;
}

.select-stores__loader {
  width: 100%;
  height: 100%;
}
