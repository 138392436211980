.wrapper {
	margin-bottom: 20px;
}

.radio_btn {
  display: inline-block;
}

.radio_btn input[type=radio] {
	display: none;
}
.radio_btn label {
	display: inline-block;
  	color: #000;
	cursor: pointer;
	font-size: 14px;
	padding: 5px 20px;
	line-height: 34px;
	border-radius: 8px;
	user-select: none;
}
 
.radio_btn input[type=radio]:checked + label {
	background: #ececec;
	color: #000;
  	font-weight: 900;
}

.radio_btn label:hover {
	color: #666;
}
