@supports not (--test: #fff) {
  .btn--success {
    background-color: #2aad5b;
  }
  .btn--danger {
    background-color: #ff5661;
  }
  .btn--gray {
    color: #2b3847;
  }
  .btn--accent {
    background: #41b0bf;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 600 15px/52px 'Work Sans', sans-serif;
  text-align: center;
  color: #ffffff;
  padding: 0 24px;
  height: 40px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  transition: opacity 0.5s;
  text-decoration: none;
}

.btn .onollo__loader {
  height: 100%;
}

.btn:hover {
  opacity: 0.75;
}

.btn--alert {
  display: inline-flex;
}

.btn--wide {
  min-width: 150px;
}

.btn--success {
  background-color: var(--color-green);
}
.btn--danger {
  background-color: var(--color-danger);
}

.btn--gray {
  color: var(--color-blue);
  background-color: rgba(56, 65, 68, 0.05);
}

.btn--black,
.btn--black-transparent {
  font-size: 15px;
  height: 40px !important;
  line-height: 1;
  font-weight: 600;
}

.btn--black {
  background: #000;
}

.btn--black-transparent {
  color: #000;
  background-color: transparent;
}

.btn--black-border,
.btn--lightGreen,
.btn--yellow {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 10px 35px;
  font: 600 15px/25px 'AvenirNext', sans-serif;
  min-width: 150px;
}

.btn--black-border.btn--small {
  font: 500 16px 'AvenirNext', sans-serif;
}

.btn--accent {
  background: var(--color-accent);
  color: #ffffff;
}

.btn--transparent {
  border: solid 1px #ffffff;
  color: #ffffff;
  background: transparent;
}
.btn--transparent:hover {
  color: #445b64;
  background: #ffffff;
  opacity: 1;
}
.btn--transparentBorder {
  border: solid 1px var(--color-dark) !important;
  color: var(--color-dark);
  background: rgba(0, 0, 0, 0);
}

.btn--transparentWithoutBorder {
  border: none;
  color: var(--color-dark);
  background: rgba(0, 0, 0, 0);
}

.btn--social {
  min-width: 270px;
  padding: 0 35px 0 18px;
  flex-shrink: 0;
  font-size: 15px;
  color: #262626;
}
.btn--store {
  color: #384144;
  line-height: 1.1;
}

.btn--add {
  padding: 0 24px;
}

.btn--add i {
  display: block;
  width: 32px;
  height: 32px;
  background-image: url('../../../assets/images/icons/plus.svg');
  background-repeat: no-repeat;
  padding-right: 14px;
  margin-top: 14px;
}

.btn--social i,
.btn--store i {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url('../../../assets/images/sprite.svg');
  background-repeat: no-repeat;
  margin-right: 14px;
}

.btn--social span {
  width: 77%;
}

.btn--store i {
  background-size: contain;
  background-position: center;
  border-radius: 0;
}

.btn--social-youtube {
  background: rgba(255, 38, 1, 0.05);
  border: 1px #e80900 solid;
}
.btn--social-youtube i {
  background-image: url('../../../assets/images/icons/youtube_rect.svg');
  background-size: contain;
  background-position: center;
  border-radius: 0;
}

.btn--social-pinterest {
  background: #cb2027;
  color: #ffffff;
}
.btn--social-pinterest i {
  background-image: url('../../../assets/images/icons/pinterest-white.svg');
  background-size: 100%;
  background-position: 0 0;
}

.btn--social-facebook {
  background: #4267b2;
  color: #ffffff;
}
.btn--social-facebook i {
  background: url('../../../assets/images/icons/facebook-white.svg') center center no-repeat;
}

.btn--social-facebook-ig {
  background: #ffffff;
  color: #000000;
  border: 1px black solid;
}
.btn--social-facebook-ig i {
  width: 69px;
  border-radius: 0;
  background: url('../../../assets/images/icons/fb-ig.svg') center center no-repeat;
}

.btn--social-twitter {
  background: rgba(29, 161, 243, 0.05);
  border: 1px solid #1da1f3;
}
.btn--social-twitter i {
  background-position: -86px -194px;
}

.btn--social-linkedin {
  background: #0077b5;
  color: #ffffff;
}
.btn--social-linkedin i {
  background: url('../../../assets/images/icons/linkedin-white.svg') center center no-repeat;
}

.btn--social-twitter.btn--small i {
  background-position: -355px -355px;
}

.btn--social-instagram {
  background: rgba(145, 58, 174, 0.05);
}
.btn--social-instagram i {
  background-position: -142px -194px;
}

.btn--store-shopify {
  background-color: rgba(149, 191, 71, 0.3);
}
.btn--store-shopify i {
  background-image: url('~assets/images/icons/shopify.svg');
}
.btn--store-magento {
  background-color: rgba(243, 134, 87, 0.25);
}
.btn--store-magento i {
  background-image: url('~assets/images/icons/magento.svg');
}

.btn--store-woocommerce {
  background-color: rgba(150, 88, 138, 0.25);
}
.btn--store-woocommerce i {
  background-image: url('~assets/images/icons/woo.svg');
}

.btn--store-bigcommerce {
  background-color: rgba(13, 82, 255, 0.25);
}
.btn--store-bigcommerce i {
  background-image: url('~assets/images/icons/bigcommerce.svg');
}

.btn--store-wix {
  background-color: rgb(156, 187, 229);
}
.btn--store-wix i {
  background-image: url('~assets/images/icons/wix-logo.svg');
}

.btn--store-wish {
  background-color: rgb(189, 209, 238);
}
.btn--store-wish i {
  background-image: url('~assets/images/icons/wish-logo.svg');
}

.btn--crazy {
  background-image: linear-gradient(158deg, #f15b2d, #f15b2d 14%, #f47b27 76%, #f47b29);
}

.btn--disabled,
.btn--disabled:hover {
  opacity: 0.3;
  cursor: not-allowed;
}

.btn--loading {
  position: relative;
  pointer-events: none;
  z-index: 1;
}

.onollo__loader--btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
}

.btn--small {
  height: auto;
  padding: 10px 15px;
  line-height: 20px;
}

.btn--small i {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.btn--small.btn--social-facebook-ig i {
  width: 41px;
}

.btn--main-small {
  height: auto;
  line-height: normal;
  font-weight: normal;
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 14px;
  cursor: pointer;
  border: 0;
  transition: all 0.25s ease;
}

.btn--success.btn--hover-accent {
  color: var(--color-blue);
  background-color: #f5f8f8;
}

.btn--success.btn--hover-accent:hover {
  opacity: 1;
  background-color: var(--color-green);
  color: #fff;
}

.albumName__field .btn--main-small,
.post_modal__textarea .btn--main-small {
  margin: 2px 4px;
  display: inline-block;
  padding: 3px 8px 2px 8px;
  background-color: #f6f8f8;
  border-radius: 18px;
  font-size: 13px;
  text-align: center;
  text-transform: lowercase;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.albumName__field .btn--success.btn--hover-accent.selected,
.albumName__field .btn--success.btn--hover-accent:hover,
.post_modal__textarea .btn--success.btn--hover-accent.selected,
.post_modal__textarea .btn--success.btn--hover-accent:hover {
  background-color: #424446;
  color: #fff;
}

.btn--lightGreen {
  border: none;
  background-color: #589885;
  color: #fff;
}

.btn--yellow {
  border: none;
  background-color: #f7cb90;
  color: #000;
}


.btn--lock,
.btn--locked {
  position: relative;
}

.btn--lock:before,
.btn--locked:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.btn--locked:after {
  content: '';
  display: block;
  width: 9px;
  height: 15px;
  background: url('~assets/images/icons/lock.svg') center no-repeat;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 3;
}

.btn--lock:after {
  content: '';
  display: block;
  width: 9px;
  height: 15px;
  background: url('~assets/images/icons/lock.svg') center no-repeat;
  position: absolute;
  left: 15px;
  bottom: 12px;
  z-index: 3;
}

.btn--whiteFill {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.btn--blue {
  background-color: #3033a9;
  color: #fff;
}

.btn--darkBlue {
  background-color: #1d1831;
  color: #fff;
  height: auto;
}

.btn--red-border {
  background-color: #fff;
  color: #ff0000;
  border: 1px solid #ff0000;
  padding: 10px 21px;
  height: auto;
  font: 500 16px 'AvenirNext', sans-serif;
  min-width: 150px;
}

.btn--narrow {
  min-width: auto;
  line-height: 10px !important;
}

.btn--bitly {
  border: 1px solid var(--color-content-border);
}

.btn--bitly i {
  background-size: 100%;
  background-image: url('~assets/images/icons/bitly.svg');
}

.btn--blackWithWhiteBorder {
  height: auto;
  font: 500 16px/19px 'Work Sans', sans-serif;
  border: 1px solid #fff;
  background-color: #000;
  padding: 6px 18px;
}
