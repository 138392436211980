.page-with-sidebar {
    display: flex;
    min-height: calc(100vh - 85px);
    background-color: #fff;
    color: #000;
}

.page-with-sidebar__sidebar {
    background-color: #F9FAFA;
    width: 180px;
    min-width: 180px;
    padding: 30px;
}

.page-with-sidebar__content {
    padding: 0 20px;
}

.sidebar, .sidebar__nav {
    display: block;
}

.sidebar__item:not(:last-child) {
    margin-bottom: 32px;
}

.sidebar__link {
    font-size: 15px;
}

.page-with-sidebar__title {
    margin-top: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

@media (max-width: 966px) {
    .page-with-sidebar {
        flex-wrap: wrap;
    }
    .page-with-sidebar__sidebar {
        background-color: #fff;
        width: 100%;
        border-bottom: 1px solid #D6D6D6;
        padding: 30px 0 20px
    }
    .sidebar__nav {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 10px;
    }
    .sidebar__nav:after {
        content: '';
        padding-left: 1px;
    }
    .sidebar__item {
        margin-bottom: 0!important;
        margin-right: 20px;
    }
    .sidebar__item:first-child {
        margin-left: 20px;
    }
    .sidebar__link {
        white-space: nowrap;
    }
}