.error-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  max-width: 1000px;
  padding: 10px 20px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;
  border-radius: 10px;
  border: 2px solid var(--color-danger);
  background: #fff;
  color: var(--color-danger);
  box-shadow: 0 0 10px 0 var(--color-light-danger);
  font-weight: 700;
}

.error-message > pre {
   white-space: pre-wrap;
}

.error-message button {
  color: var(--color-accent);
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  text-decoration: underline;
}

.error-message button:hover {
  text-decoration: none;
}

.error-message__clear-button {
  width: 20px;
  height: 20px;
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 0 0 0 20px;
  position: relative;
}

.error-message__clear-button::after,
.error-message__clear-button::before {
  content: '';
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  border-radius: 2px;
  background: var(--color-danger);
}

.error-message__clear-button::after {
  transform: rotate(45deg);
}

.error-message__clear-button::before {
  transform: rotate(-45deg);
}

@media (max-width: 1040px) {
  .error-message {
      max-width: calc(100vw - 40px);
  }
}
